import request from '@/plugins/axios'

// 操作说明
// 分类列表
export const apiHelpMenuGetMenu = (params: any) => request.get('/help.menu/getmenu', { params })
// 删除分类
export const apiHelpMenuDeleteMenu = (data: any) => request.post('/help.menu/deleteMenu', data);
// 修改分类状态/编辑分类状态
export const apiHelpMenuUpdateMenu = (data: any) => request.post('/help.menu/updateMenu', data);
// 新增分类
export const apiHelpMenuSetMenu = (data: any) => request.post('/help.menu/setMenu', data);
// 分类详情
export const apiHelpMenuGetMenuInfo = (params: any) => request.get('/help.Menu/getMenuInfo', { params });
// 删除文章
export const apiHelpContentDeleteData = (data: any) => request.post('/help.content/deleteData', data);
// 文章列表
export const apiHelpContentGetList = (params: any) => request.get('/help.content/getList', { params });
// 文章编辑/修改状态
export const apiHelpContentUpdateData = (data: any) => request.post('/help.content/updateData', data);
// 文章详情
export const apiHelpContentGetData = (params: any) => request.get('/help.content/getData', { params });
// 文章新增
export const apiHelpContentSetData = (data: any) => request.post('/help.content/setData', data);

// 公告

// 公告列表
export const apiSystemSystemNotifyGetList = (params: any) => request.get('/system.SystemNotify/getList', { params });
// 公告删除
export const apiSystemSystemNotifyDeleteData = (data: any) => request.post('/system.SystemNotify/deleteData', data);
// 公告新增
export const apiSystemSystemNotifySetData = (data: any) => request.post('/system.SystemNotify/setData', data);
// 公告更新
export const apiSystemSystemNotifyUpdateData = (data: any) => request.post('/system.SystemNotify/updateData', data);
// 公告详情
export const apiSystemSystemNotifyGetData = (params: any) => request.get('/system.SystemNotify/getData', { params });