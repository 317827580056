
import { Component, Vue, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiHelpMenuSetMenu, apiHelpMenuGetMenuInfo, apiHelpMenuUpdateMenu, apiHelpMenuGetMenu } from '@/api/instructions'
import { Getter } from "vuex-class" 
@Component({
    components: {
        MaterialSelect
    }     
})
export default class AddCategory extends Vue {
    @Getter('lang') lang!: Array<string>
    $refs!: { form: any }
    id!: any
    loading = false
    hasPid = 0
    categoryList = []
    form = {
        name: '',
        parent_id: '',
        image: '',
        sort: '',
        status: 1,
        is_recommend: 0,
        type: 1
    }

    rules = {
        name: [
            {
                required: true,
                message: '请输入分类名称',
                trigger: ['blur', 'change']
            }
        ]
    }

    @Watch('form', { immediate: true })
    setDefaultName() {
        if (this.lang.length > 1) {
            let multiLanguage: any = {};
            for (const item of this.lang) {
                this.$set(multiLanguage, item, '');
            }
            if (this.form.name) multiLanguage = this.saveProductNames(this.form.name);
            this.form.name = multiLanguage;
        }else {
            this.form.name = this.processName(this.form.name);
        }
    }

    saveProductNames(productName: string): { [key: string]: string } {
        const lang: string[] = this.lang;

        let nameObj: { [key: string]: string } = {};

        try {
            nameObj = JSON.parse(productName);
        } catch (error) {
            nameObj.default = productName; // 将传入的值赋给"default"属性
            nameObj.zh_cn = productName; // 将传入的值赋给"zh_cn"属性
        }

        let savedNames: { [key: string]: string } = {};

        for (let language of lang) {
            if (nameObj[language]) {
                savedNames[language] = nameObj[language];
            } else {
                savedNames[language] = ''; // 或设置一个默认值，如果没有对应语言的属性
            }
        }

        return savedNames;
    }
    
    processName(name: string) {
        try {
            const jsonData = JSON.parse(name);
            if (jsonData && jsonData.zh_cn) {
            return jsonData.zh_cn;
            }
        } catch (error) {
            // 处理普通字符串的情况
            return name;
        }
    }

    processData(data: any) {
        return data.map((item: any) => {
            if (this.isJSONString(item.label)) {
                const parsedData = JSON.parse(item.label);
                if (parsedData && parsedData.zh_cn) {
                    item.label = parsedData.zh_cn;
                }
            }

            if (item.children) {
                item.children.forEach((children: any) => {
                    if (this.isJSONString(children.label)) {
                        const parsedSonData = JSON.parse(children.label);
                        if (parsedSonData && parsedSonData.zh_cn) {
                            children.label = parsedSonData.zh_cn;
                        }
                    }

                    if (children.children) {
                        children.children.forEach((nestedSon: any) => {
                            if (this.isJSONString(nestedSon.label)) {
                                const parsedNestedSonData = JSON.parse(nestedSon.label);
                                if (parsedNestedSonData && parsedNestedSonData.zh_cn) {
                                    nestedSon.label = parsedNestedSonData.zh_cn;
                                }
                            }
                        });
                    }
                });
            }

            return item;
        });
    }
    
    isJSONString(str: string): boolean {
        try {
            JSON.parse(str);
            return true;
        } catch (error) {
            return false;
        }
    }

    handleSave() {
        this.$refs.form.validate((valid: boolean) => {
            if (valid) {
                let form = JSON.parse(JSON.stringify(this.form));
                if (this.lang.length > 1) {
                    if (this.checkEmptyValues(form.name)) return this.$message.error('请输入完整的分类名称');
                    form.name = JSON.stringify(form.name);
                }
                if (!this.hasPid) {
                    form.parent_id = ''
                }
                const api = this.id ? apiHelpMenuUpdateMenu(form) : apiHelpMenuSetMenu(form)
                api.then(() => {
                    this.$router.go(-1)
                })
            } else {
                return false
            }
        })
    }

        // 检查是否包含空字符串
        checkEmptyValues(obj: any) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === '') {
                return true;
            }
        }
        return false;
    }

    getCategoryDetail() {
        this.loading = true
        apiHelpMenuGetMenuInfo({
            id: this.id
        }).then((res: any) => {
            if (res.parent_id) {
                this.hasPid = 1
            }
            this.form = res;
            this.loading = false
        })
    }

    getCategoryList() {
        apiHelpMenuGetMenu({
            type: this.form.type
        }).then((res: any) => {
            res?.lists.forEach((item: any) => {
                item.sons &&
                    item.sons.forEach((sitem: any) => {
                        delete sitem.sons
                    })
            })
            let categoryList = res?.lists
            categoryList = this.processData(categoryList);
            this.categoryList = categoryList;
        })
    }

    created() {
        this.id = this.$route.query.id
        this.id && this.getCategoryDetail()
        this.getCategoryList()
    }
}
